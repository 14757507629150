// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--KcyeC";
export var btnBlock = "banner-module--btnBlock--I8s5R";
export var container = "banner-module--container--IOJJi";
export var hideMobBlock = "banner-module--hideMobBlock--oa0W0";
export var img = "banner-module--img--IiVHt";
export var mobBtn = "banner-module--mobBtn--qN4Wo";
export var red = "banner-module--red--IrG0h";
export var secondContainer = "banner-module--secondContainer--DhKjU";
export var section = "banner-module--section--b3Oso";
export var text = "banner-module--text--LKKnb";